import * as React from "react";
import styled from "styled-components";
import { Table, Tbody, Td, Th, Thead, Tr } from "../table.js";
import { useLocalization } from "gatsby-theme-i18n";
import PageContainer from "../pageContainer.js";
import { Button, ButtonDownloadIcon, ButtonIconContainer } from "../button.js";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { ArrowContainer, PageLink, Pagination } from "../pagination.js";
import { when } from "../../styles/theme.js";

const translations = {
  en: {
    announcement: "Announcement",
    date: "Date",
    document: "Document",
    download: "Download",
  },
  zh: {
    announcement: "公告",
    date: "發佈日期",
    document: "文件",
    download: "下戴文件",
  },
};

const Container = styled(PageContainer)`
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

const InnerContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const TableContainer = styled.div`
  margin-top: 5rem;
  margin-left: -${(props) => props.theme.pageContainerWidth.xs}rem;
  margin-right: -${(props) => props.theme.pageContainerWidth.xs}rem;

  ${when("sm")} {
    margin-left: -${(props) => props.theme.pageContainerWidth.sm}rem;
    margin-right: -${(props) => props.theme.pageContainerWidth.sm}rem;
  }

  ${when("md")} {
    margin-left: -${(props) => props.theme.pageContainerWidth.md}rem;
    margin-right: -${(props) => props.theme.pageContainerWidth.md}rem;
  }

  ${when("lg")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const AnnouncementTable = styled(Table)`
  margin-top: 5rem;
`;

const StyledTh = styled(Th)`
  &:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  ${when("lg")} {
    &:first-child {
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
    }

    &:last-child {
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
`;

const DownloadButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
  white-space: nowrap;
`;

const LeftArrow = styled(BsFillCaretLeftFill)`
  display: block;
`;

const RightArrow = styled(BsFillCaretRightFill)`
  display: block;
`;

const DateTh = styled(StyledTh)`
  display: none;

  ${when("sm")} {
    display: table-cell;
  }
`;

const DateTd = styled(Td)`
  display: none;

  ${when("sm")} {
    display: table-cell;
  }
`;

const DateXs = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.darkGrey};

  ${when("sm")} {
    display: none;
  }
`;

const AnnouncementsPageSection = ({ announcements, totalPage, page }) => {
  // const
  const { locale } = useLocalization();

  const translation = translations[locale];

  const pages = [];
  for (let i = 0; i < totalPage; i++) {
    pages.push(i + 1);
  }

  return (
    <Container as={"section"}>
      <InnerContainer>
        <TableContainer>
          <AnnouncementTable>
            <Thead>
              <Tr>
                <StyledTh>{translation.announcement}</StyledTh>
                <DateTh>{translation.date}</DateTh>
                <StyledTh>{translation.document}</StyledTh>
              </Tr>
            </Thead>
            <Tbody>
              {announcements.map((announcement, index) => {
                const date = parseISO(announcement.date);
                return (
                  <Tr key={index}>
                    <Td>
                      {announcement.title}
                      <DateXs>{format(date, "dd.MM.yyyy")}</DateXs>
                    </Td>
                    <DateTd>{format(date, "dd.MM.yyyy")}</DateTd>
                    <Td>
                      <DownloadButton
                        as={"a"}
                        href={announcement.announcementDocument?.publicUrl}
                      >
                        <ButtonIconContainer>
                          <ButtonDownloadIcon />
                        </ButtonIconContainer>
                        {translation.download}
                      </DownloadButton>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </AnnouncementTable>
        </TableContainer>
        <Pagination>
          <ArrowContainer
            link={
              page === 1
                ? null
                : `/investor-relationship/announcements/${page - 1}`
            }
          >
            <LeftArrow />
          </ArrowContainer>
          {pages.map((pageNum, index) => {
            return (
              <PageLink
                key={index}
                href={`/investor-relationship/announcements/${pageNum}`}
                isActive={pageNum === page}
              >
                {pageNum}
              </PageLink>
            );
          })}
          <ArrowContainer
            link={
              totalPage === page
                ? null
                : `/investor-relationship/announcements/${page + 1}`
            }
          >
            <RightArrow />
          </ArrowContainer>
        </Pagination>
      </InnerContainer>
    </Container>
  );
};

export default AnnouncementsPageSection;
