import styled from "styled-components";

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  font-weight: 500;
`;

export const Thead = styled.thead`
  background: ${(props) => props.background || props.theme.colors.red};
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  &:nth-child(even) {
    background: #fff;
  }
`;

export const Th = styled.th`
  padding: 1.25rem 2rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;

  &:first-child {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  &:last-child {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
  }
`;

export const Td = styled.td`
  padding: 1.25rem 2rem;
  text-align: center;
`;
