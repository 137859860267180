import * as React from "react";
import styled from "styled-components";
import Link from "./link.js";

export const Pagination = styled.nav`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageLink = styled(Link)`
  display: block;
  padding: 1rem;
  color: ${(props) =>
    props.isActive ? props.theme.colors.black : props.theme.colors.gray};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const InactiveArrowLink = styled.div`
  padding: 1rem;
  opacity: 0.5;
  color: ${(props) => props.theme.colors.red};
`;

export const StyledArrowLink = styled(Link)`
  padding: 1rem;
  display: block;
  color: ${(props) => props.theme.colors.red};
`;

export const ArrowContainer = ({ children, link }) => {
  if (link) {
    return <StyledArrowLink href={link}>{children}</StyledArrowLink>;
  } else {
    return <InactiveArrowLink>{children}</InactiveArrowLink>;
  }
};
